import Button from "components/Buttons/Button/Button";
import icons from "helpers/icons";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AddToCartButton from "../../../components/Form/AddToCartButton/AddToCartButton";
import PlusMinusInput from "../../../components/Form/PlusMinusInput/PlusMinusInput";
import __ from "../../../config/translations/serbian";
import useAPI from "../../../hooks/useAPI";
import classes from "./Footer.module.scss";
import { useNavigate } from "react-router-dom";
import FormWrapper from "components/Form/FormWrapper";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GlobalQueries } from "../../../hooks/useRefreshQuery";
import { Prices } from "../../../components/prices";
import { getDataFromCurrentProduct } from "../../../helpers/product";

const Footer = ({ product, inWishlist, productVariant }) => {
    const { behaviours, price, inventory, selectedProduct } = getDataFromCurrentProduct({
        productVariant,
        product,
    });

    const initialAmount = behaviours?.cart?.default_loop_quantity;
    const [quantity, setQuantity] = useState(initialAmount);
    useEffect(() => {
        setQuantity(initialAmount);
    }, [initialAmount]);

    const api = useAPI();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const getQuantity = () => {
        if (quantity === 0) {
            setQuantity(initialAmount ?? 1);
            return initialAmount ?? 1;
        }
        return quantity;
    };

    const [loading, setLoading] = useState(false);
    const [isPlusMinusInputEmpty, setIsPlusMinusInputEmpty] = useState(false);

    const { data: wishlist_data, refetch } = useQuery(
        ["isInWishlist", product?.item?.basic_data?.id_product],
        async () => {
            return await api
                .get(`/wishlist/product/${product?.item?.basic_data?.id_product}`)
                .then((res) => res)
                ?.catch((err) => console.error(err));
        },
        { refetchOnWindowFocus: false }
    );

    const { mutate: deleteFromWishlist, isSuccess: isDeleted } = useMutation(
        ["deletefromWishlist", product?.item?.basic_data?.id_product],
        async () => {
            return await api
                .post(`/wishlist`, {
                    id: null,
                    id_product: +product?.item?.basic_data?.id_product,
                    quantity: 1,
                    id_product_parent: product?.item?.basic_data?.id_product_parent,
                    description: null,
                    status: null,
                    in_wishlist: false,
                })
                .then((res) => {
                    toast.success("Proizvod je uklonjen iz liste želja");
                    queryClient.invalidateQueries(GlobalQueries.Wishlist).then();
                })
                ?.catch((err) => console.error(err));
        },
        { refetchOnWindowFocus: false }
    );
    const { mutate: addToWishlist, isSuccess: isAdded } = useMutation(
        ["addToWishlist", product?.item?.basic_data?.id_product],
        async () => {
            return await api
                .post(`/wishlist`, {
                    id: null,
                    id_product: +product?.item?.basic_data?.id_product,
                    quantity: 1,
                    id_product_parent: product?.item?.basic_data?.id_product_parent,
                    description: null,
                    status: null,
                    in_wishlist: true,
                })
                .then((res) => {
                    toast.success("Proizvod je dodat u listu želja");
                    queryClient.invalidateQueries(GlobalQueries.Wishlist).then();
                })
                ?.catch((err) => console.error(err));
        },
        { refetchOnWindowFocus: false }
    );

    useEffect(() => {
        if (isDeleted || isAdded) {
            refetch();
            queryClient.invalidateQueries(GlobalQueries.Wishlist).then();
        }
    }, [isDeleted, isAdded]);

    return (
        <div className={classes.wrapper}>
            {/* Price */}
            <div className={classes.price}>
                {/* Previous price (strikethrough) */}

                {/*{product?.item?.price?.second && (*/}
                {/*    <span className={classes.inactive} onDoubleClick={showRebate(product)}>*/}
                {/*        {currencyFormat(product?.price?.second, product?.price?.currency)}*/}
                {/*    </span>*/}
                {/*)}*/}

                <div className={classes.active}>
                    <Prices price={price} inventory={inventory} selectedProduct={selectedProduct} />
                </div>
            </div>

            {/* Add to cart */}
            <div className={classes.cart}>
                <FormWrapper
                    className={`${classes.form}`}
                    onSubmit={() => {
                        const productItem = productVariant ? productVariant : product?.item;
                        api.globalAddToCart(productItem?.basic_data?.id_product, getQuantity(), loading, setLoading);
                    }}
                >
                    {product.item.inventory.inventory_defined && product?.item?.price?.price_defined ? (
                        <PlusMinusInput
                            max={productVariant ? +productVariant?.inventory?.amount : +product?.item?.inventory?.amount}
                            className={classes.input}
                            quantity={quantity}
                            setQuantity={setQuantity}
                            setIsPlusMinusInputEmpty={setIsPlusMinusInputEmpty}
                            behaviours={behaviours?.cart}
                        />
                    ) : null}

                    <AddToCartButton
                        className={classes.addToCart}
                        short={false}
                        loading={loading}
                        product={product?.item}
                        productVariant={productVariant}
                        onClick={() => {
                            setLoading(true);
                            // productVariant
                            //     ? productVariant?.inventory?.amount > 0
                            //         ? api.globalAddToCart(productVariant?.basic_data?.id_product, getQuantity())
                            //         : null
                            //     : product?.item?.inventory?.amount > 0
                            //     ? api.globalAddToCart(product?.item?.basic_data?.id_product, getQuantity())
                            //     : null;

                            if (product?.item?.product_type === "variant") {
                                if (productVariant?.inventory?.inventory_defined && productVariant?.basic_data?.id_product) {
                                    if (isPlusMinusInputEmpty) {
                                        toast.warn("Molimo unesite količinu!");
                                        setLoading(false);
                                    } else {
                                        api.globalAddToCart(productVariant?.basic_data?.id_product, quantity, loading, setLoading);
                                        setQuantity(initialAmount);
                                    }
                                } else {
                                    if (!productVariant?.basic_data?.id_product) {
                                        toast.error("Odaberite varijaciju proizvoda");
                                        setLoading(false);
                                    } else {
                                        if (productVariant?.basic_data?.id_product && (!productVariant?.inventory?.inventory_defined || !productVariant?.price?.price_defined)) {
                                            navigate(`/kontakt?slug=${productVariant?.slug}`, { state: { product: product } });
                                        }
                                    }
                                }
                            } else {
                                if (product?.item?.inventory?.inventory_defined && product?.item?.price?.price_defined) {
                                    if (isPlusMinusInputEmpty) {
                                        toast.warn("Molimo unesite količinu");
                                        setLoading(false);
                                    } else {
                                        api.globalAddToCart(product?.item?.basic_data?.id_product, quantity, loading, setLoading);
                                        setQuantity(initialAmount);
                                    }
                                } else navigate(`/kontakt?slug=${product?.item?.slug}`, { state: { product: product } });
                            }
                        }}
                    />
                </FormWrapper>
                <Button
                    disabled={loading}
                    className={`${classes.wishlist} ${wishlist_data?.in_wishlist && classes.inWishlist}`}
                    label={<img src={icons.wish_list} alt={icons.wish_list} className={`${classes.heart} `} />}
                    onClick={() => {
                        if (wishlist_data?.in_wishlist) {
                            return deleteFromWishlist();
                        }
                        addToWishlist();
                    }}
                />
            </div>
        </div>
    );
};

export default Footer;
